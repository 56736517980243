import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire Nuxt.js Developers`,
    text: "Hire Right Developers",
    content: "Hire Nuxt.js developers from Octet to create lightweight, search engine-friendly websites with dynamic UI and content.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Developers',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire Nuxt.js Developers',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Drive Business Growth with Our Nuxt.js Developers',
    text: "Our committed Nuxt.js developers for hire support you at every stage of the process and help you take your concept to the next level.",
    list: [
      {
        title: 'Simplified UX',
        text: "Everything from well-positioned widgets to interesting content and faultless design, let our Nuxt.js developers handle it."
      },
      {
        title: 'Enhancing Visibility',
        text: `Well-crafted front-end development is critical to the app's visibility. Our Nuxt.js developers for hire ensure that you never lose potential customers.`
      },
      {
        title: 'Increasing New Users',
        text: 'With our dedicated NuxtJS developers, acquiring new users and introducing a new feature to build user trust are straightforward processes.'
      }
    ],
    image: ["hire-content-nuxt.webp"],
    titleText: 'Dedicated NuxtJS Developers',
    alt: 'Benefits of hiring Nuxt.js developers'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top Nuxt.js Developers for Hire',
    text: "Leading companies trust Octet Design Studio to hire Nuxt js developers to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire NuxtJS Developers In 48 Hours',
        text: 'Hire Nuxt js developer to ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our Nuxt.js developers to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our dedicated Nuxt.js developers commit 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Work with the dedicated Nuxt.js developers and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-nuxt.webp"],
    titleText: 'Hire Nuxt.js developers',
    alt: 'Hire Nuxt js developers from a leading Nuxt.js development company'
  }
  const commonContent3 = {
    title: `3 Steps to Hire NuxtJS Developers`,
    text: "Experience the Octet advantage by hiring Nuxt.js developers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: `Provide us with your project requirements, and we will share the resumes of our dedicated Nuxt.js developers for hire who best match your needs and business goals.`
      },
      {
        title: 'Step 2: Interview Developers',
        text: "Interview and evaluate our skilled Nuxt.js developers to select the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire Nuxt.js developer, onboard them within 48 hours so they can dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-nuxt.webp"],
    titleText: 'Hire NuxtJS developer',
    alt: 'Simple steps to hire NuxtJS developers from Octet'
  }
  const cta = {
    title: `Hire Exceptional Talent to Fuel Your Ideas`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce Nuxt.JS Developers",
      content: "Our Nuxt.js developers use various tools and software to develop apps that meet users' needs and help convert visitors into customers. Hire NuxtJS developer to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "Nuxt.js Developer for SaaS",
      content:
        "SaaS Nuxt.js developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire NuxtJS developers who will be creative problem solvers and develop beautiful, functional apps and websites that enhance user experience.",
    },
    {
      title: "Enterprise Nuxt.js Developers",
      content: "Hire Nuxt JS developers who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our dedicated NuxtJS developers will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire Nuxt.js Developers to Stand Out`,
    des: `Hire top Nuxt JS developers who have built successful worldwide products across domains using NuxtJS.`,
    tabs: ["SPA", "Server-Side Rendered Apps", "Progressive Web Apps", "API Integration and Development"],
    slides: [
      {
        title: 'Single Page Applications(SPA)',
        desc1: "Hire Nuxt js developers to develop Single-Page Applications. Our Nuxt.js developers ensures a fast, seamless user experience by dynamically loading content without refreshing the entire page.",
        // desc2: 'Hire UI designer for mobile app designing to obtain consistent user-centric design, better accessibility, and intuitive layouts.',
        list: [
          'Expertise in Web Applications',
          'Delivering Uninterrupted Experience',
          'Developing Ideal User Interfaces'
        ]
      },
      {
        title: 'Server-Side Rendered Applications',
        desc1: "Hire NuxtJS developers who excel in building SSR applications, where the server generates the HTML, ensuring faster initial load times and better SEO.",
        // desc2: 'Enhance your brand’s online presence with a focus on user-friendly, innovative design solutions tailored to your unique web UI UX needs.',
        list: [
          'Expertise in Content-heavy Sites',
          'Boosting Online Visibility',
          'Improving User Interaction'
        ]
      },
      {
        title: 'Progressive Web Applications',
        desc1: "Hire NuxtJS developer to create Progressive Web Applications that provide a native app-like experience with features like offline access, push notifications, and fast load times.",
        // desc2: 'Our UI UX experts create visually appealing mobile designs that ensure conciseness and efficiency to deliver a better user experience.',
        list: [
          'Providing Accessibility',
          'Expertise in Service-based Applications',
          'Delivering Cross-platform Accessibility'
        ]
      },
      {
        title: 'API Integration and Development',
        desc1: "Hire dedicated NuxtJS developers to build robust applications that interact seamlessly with APIs. Our NextJS developers for hire integrate third-party APIs or develop custom APIs to extend the functionality of your Nuxt.js applications.",
        // desc2: "Our UI UX designers will create workflows and optimize user experience for desktop devices to meet users' needs and expectations.",
        list: [
          'Expertise in Custom Web Solutions',
          'Providing Real-time Data',
          'Developing External Service Interaction'
        ]
      }
    ],
    altOne: 'Single Page Applications',
    titleOne: 'Single Page Applications',
    altTwo: 'Server-Side Rendered Applications',
    titleTwo: 'Server-Side Rendered Applications',
    altThree: 'Progressive Web Applications',
    titleThree: 'Progressive Web Applications',
    altFour: 'API Integration and Development',
    titleFour: 'API Integration and Development',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Hire NUxtJS developer who are passionate and committed to developing captivating apps, websites, and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'NuxtJs Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire NuxtJS Developer from Octet?",
    "How can you Hire Nuxt JS Developers from Octet?",
    "Which companies Hire NuxtJS Developers?",
    "Do your Nuxt.js Developers for Hire comes with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of developers who specialize in crafting engaging and functional interfaces.`,
        `Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `Hire Nuxt.js developers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring Nuxt.js developer from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring Nuxt js developers from us, onboard them to your project team and give them access to necessary resources.`,
      ]
    },
    {
      para: [
        `Nuxt.js developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring Nuxt js Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Nuxt js Developers to Build Your Vision",
    para: 'Our large pool of extremely knowledgeable and experienced Nuxt.js developers can assist you in developing streamlined, effective, and user-friendly online apps.',
    content: [
      {
        title: "UI UX Design",
        text: "Hire Nuxt js Developers to create responsive and mobile-friendly designs. Our Nuxt.js developers for hire ensure a consistent user experience across different devices and screen sizes.",
      },
      {
          title: "Usability Testing",
          text: "Our Nuxt.js Developers ensure application reliability and stability, and comprehensive testing strategies, including unit tests, integration tests, and end-to-end tests, must be implemented.",
        },
        {
          title: "Custom App Development",
          text: "Hire dedicated NuxtJS developers to build tailored web applications from scratch using Nuxt.js, ensuring they meet specific business requirements and goals.",
        },
      {
        title: "GraphQL Integration",
        text: "Our Nuxt.js developers for hire integrate GraphQL to manage data fetching efficiently and reduce the number of API requests.",
      },
      {
        title: "UX Consultation",
        text: "Hire NuxtJS developers to continuously improve the user experience based on user feedback and analytics data, ensuring the application remains user-friendly and engaging.",
      },
      {
        title: "Branding",
        text: "Our dedicated NuxtJS developers create custom themes and styles to ensure the application aligns with the brand identity and provides a unique look and feel.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire NuxtJS Developer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of developers who specialize in crafting engaging and functional interfaces. 
          
          Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          Hire Nuxt.js developers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire Nuxt JS Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Nuxt.js developer from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring Nuxt js developers from us, onboard them to your project team and give them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire NuxtJS Developers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Nuxt.js developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Do your Nuxt.js Developers for Hire comes with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Nuxt js Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money."
              }
            }]
          }                                         
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Nuxt.js Developers">
        <div>
          <HireBanner data={banner} pageName="Hire UI/UX Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire NuxtJS Developers <span class="h1-span">for your Industry</span>`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our Nuxt.js developers, hiring Nuxt.js developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire Nuxt.js Developers | Nuxt.js Developers"
    description="Hire Nuxt.js Developers for your upcoming project. Our Nuxt.js developers develop high-performing, custom web applications."
  />
);